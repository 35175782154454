.container {
  ion-card {
    // margin-top: 0 !important;
    // margin-bottom: 0 !important;
    background: #fff;
  }
}

.loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.successIcon {
  color: var(--ion-color-success);
  margin-right: 0.5rem;
}

.warningIcon {
  color: var(--ion-color-warning);
  margin-right: 0.5rem;
}

.errorIcon {
  color: var(--ion-color-danger);
  margin-right: 0.5rem;
}

.amountContainer {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  padding-top: calc(1rem + env(safe-area-inset-top));

  h1 {
    text-align: center;
    margin-bottom: 3rem;
  }

  ion-button {
    font-family: 'Might Makes Right Pro BB';
    font-size: 24px;
    height: 42px;
  }
}

.description {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 220px;
  }

  p {
    font-size: 12px;
    text-align: center;
  }
}
