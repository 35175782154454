.container {
  p:first-child {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  p {
    text-align: center;
    margin-bottom: 0;
    margin-top: 1rem;
  }
}

.qrContainer {
  min-height: 240px;
  margin-top: 1rem;
}

.depositContainer {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.isLow {
  :global(.big-number), .balanceTitle {
    color: var(--color-red);
  }
  .depositContainer ion-button {
    --background: var(--color-red);
  }
}
