.container {
  margin-top: 1rem !important;

  :global ion-card-content {
    padding: 1rem !important;
  }

  &.card {
    background: var(--ion-background-color);
    padding: 1rem;
  }

  i {
    margin-right: 0.5rem;
  }

  &.error i {
    color: var(--ion-color-danger);
  }

  &.success i {
    color: var(--ion-color-success);
  }

  &.warning i {
    color: var(--ion-color-warning);
  }
}
