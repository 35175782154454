.wrapper {
  max-width: 600px;
  margin: 0 auto;

  :global ion-card {
    margin: 0;
  }
}

.forgot {

}
