.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-top: calc(1rem + env(safe-area-inset-top));
  height: 15vh;

  img {
    max-height: 72px;
    max-width: 90vw;
  }
}
