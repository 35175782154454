@font-face {
  font-family: 'Gill Sans Bold';
  src: url('../fonts/GillSans-UltraBold.eot');
  src: url('../fonts/GillSans-UltraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GillSans-UltraBold.woff2') format('woff2'),
  url('../fonts/GillSans-UltraBold.woff') format('woff'),
  url('../fonts/GillSans-UltraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSansMTPro-Book.eot');
  src: url('../fonts/GillSansMTPro-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GillSansMTPro-Book.woff2') format('woff2'),
  url('../fonts/GillSansMTPro-Book.woff') format('woff'),
  url('../fonts/GillSansMTPro-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Might Makes Right Pro BB';
  src: url('../fonts/MightMakesRightProBB-Bold.eot');
  src: url('../fonts/MightMakesRightProBB-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MightMakesRightProBB-Bold.woff2') format('woff2'),
  url('../fonts/MightMakesRightProBB-Bold.woff') format('woff'),
  url('../fonts/MightMakesRightProBB-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h2 {
  font-size: 19px;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
}

.big-number {
  text-align: center;
  font-size: 32px;
  line-height: 36px;
  display: block;
}
