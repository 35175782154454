ion-content {
  --background: transparent;
}

body {
  background: var(--color-yellow);
}

body > iframe {
  pointer-events: none;
}

ion-footer {
  padding: 1rem;
  padding-bottom: var(--ion-safe-area-bottom, 1rem);
  .footer-md::before {
    display: none !important;
  }
}

.alert-button.sc-ion-alert-ios {
  color: var(--color-grey);
}

.toolbar-background {
  background: transparent;
}

ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

ion-item ion-icon {
  font-size: 20px;
  color: var(--ion-tab-bar-color);
}

ion-button {
  --background: var(--color-grey);
  --color: var(--color-yellow);
  --background-activated: var(--color-orange);
  font-family: 'Gill Sans Bold';
  --border-radius: 24px;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  height: 36px;
  margin: 0;

  &.small {
    height: 24px;
    font-size: 15px;
    line-height: 18px;
  }
}

ion-label {
  margin: 10px 0 0 0 !important;
  height: 24px;
  transform: none !important;
  font-size: 14px !important;
}

ion-button::part(native) {
  padding-top: 1px;
  --padding-start: 24px;
  --padding-end: 24px;
  box-shadow: none;
}

ion-item {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --inner-border-width: 0;
  --color-activated: var(--color-grey);
  --color-focused: var(--color-grey);
  --highlight-height: 0;
  padding: 0 !important;
}

ion-item::part(native) {
  background: none;
  border: none;

  .item-inner {
    padding: 0;
    border: none;
  }
}

ion-input {
  border: 5px solid var(--color-grey);
  border-radius: 24px;
  background: #fff !important;
  --padding-start: 1rem !important;
  --padding-end: 1rem !important;
  --ion-font-family: 'Gill Sans';
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 0.01em;
  height: 48px;
}

.has-error {
  ion-label {
    color: var(--color-orange) !important;
  }
  ion-input {
    border-color: var(--color-orange);
  }
}

input {
  padding-top: 6px !important;
  padding-bottom: 4px !important;
  height: 38px;
}

ion-router-link, a {
  color: var(--color-grey);
  border-bottom: 2px solid var(--color-grey);
  padding-bottom: 2px;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
}

.header-md::after {
  background-image: none;
}

hr {
  background: var(--ion-tab-bar-color);
}

.header-background {
  backdrop-filter: none;
}

ion-tab-bar {
  border: none;
  height: 72px;
  position: relative;
  &:before {
    content: "";
    height: 7px;
    width: 100%;
    position: absolute;
    top: 0;
    background: url('../images/tabs.png') no-repeat;
    background-size: cover;
  }
}

ion-tab-button {
  background: transparent;

  & > span {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
  }

  &.tab-selected > span {
    filter: invert(44%) sepia(31%) saturate(2517%) hue-rotate(343deg) brightness(96%) contrast(95%);
  }
}

#tab-button-charts > span {
  background-image: url('../images/stat.svg');
}

#tab-button-scan > span {
  background-image: url('../images/scan.svg');
}

#tab-button-profile > span {
  background-image: url('../images/avatar.svg');
}


input[type=checkbox] {
  position: absolute;
  height: 0;
  width: 0;
  cursor: pointer;
  opacity: 0;

  & ~ label {
    font-size: 14px;
    display: block;
    position: relative;
    padding-left: 32px;
    padding-top: 2px;

    &:before {
      background-image: url(../images/icon-checkbox-unchecked.png);
      background-size: 100% auto;
      display: inline-block;
      width: 24px;
      height: 24px;
      content: "";
      float: left;
      margin: 5px 6px 0 0;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.error:before {
      background-image: url(../images/icon-checkbox-error.png);
    }
  }

  &:checked ~ label:before {
    background-image: url(../images/icon-checkbox-checked.png);
  }
}

.bottom-addon {
  margin-top: 3rem;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
}

ion-toast::part(message) {

  i {
    margin-right: 0.5rem;
    width: 1rem;
  }

  &.error i {
    color: var(--ion-color-danger);
  }

  &.success i {
    color: var(--ion-color-success);
  }
}

.alert-message {
  color: var(--color-grey) !important;
}

.alert-button-group {
  .alert-button.secondary {
    color: var(--color-grey) !important;
  }
  .alert-button.danger {
    color: var(--ion-color-danger) !important;
  }
}
