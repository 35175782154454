.container {
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    padding: 0.5rem 0.5rem 0;
    background: #fff;
  }

  svg {
    position: relative;
    &:after {
      position: absolute;
      top:50%;
      left:50%;
      height: 1rem;
      width: 1rem;
      background: red;
      content: "";
    }
  }

}
