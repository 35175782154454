.wrapper {
  ion-content {
    --padding-start: 1rem;
    --padding-end: 1rem;
  }
  ion-card:first-of-type {
    margin-top: 0 !important;
  }
  .container {
    max-width: 480px;
    margin: 0 auto;
  }
}
