.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  border: none;
  img {
    height: 42px;
    margin-bottom: 8px;
  }
}
