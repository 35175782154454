.container {
  position: relative;
  width: 100%;
}

.input {
  padding-right: 3rem !important;
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  color: var(--color-grey);
  font-size: 20px;
  z-index: 100;
}
